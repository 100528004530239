import React, { useState, useEffect } from 'react';
import './BannerAd.css';
import appData from './apps.json';
import ReactGA from "react-ga4";

export const getAppStoreLink = (app, deviceOS) => {
  if (deviceOS === 'ios') {
    return app.ios;
  }
  
  if (deviceOS === 'android') {
    return app.android;
  }

  return "https://www.zeeronstudio.com"
};

const BannerAd = ({ deviceOS }) => {
  const [apps, setApps] = useState([]);
  const getNextAdIndex = (prevIndex, apps) => {
    let nextIndex = (prevIndex + 1) % apps.length;
    
    // If on iOS, skip apps without iOS links
    if (deviceOS === 'ios') {
      while (apps[nextIndex].ios === "" && nextIndex !== prevIndex) {
        nextIndex = (nextIndex + 1) % apps.length;
      }
    }
    
    return nextIndex;
  };

  // State to manage current ad index
  const [currentAdIndex, setCurrentAdIndex] = useState(getNextAdIndex(Math.floor(Math.random() * appData.length), appData));
  
  const [isVisible, setIsVisible] = useState(true);

  // Load apps from JSON file
  useEffect(() => {
    // Validate and set apps
    if (appData && Array.isArray(appData) && appData.length > 0) {
      setApps(appData);
    } else {
      console.error('No apps data found or invalid data format');
    }
  }, []);

  // Add this inside the BannerAd component, after the useEffect for loading apps
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentAdIndex((prevIndex) => {
        return getNextAdIndex(prevIndex, apps);
      });
    }, 5000); // Rotate every 5 seconds

    return () => clearInterval(timer);
  }, [apps]);

  // If no apps are loaded or banner is not visible, return null
  if (apps.length === 0 || !isVisible) {
    return null;
  }

  // Current ad based on index
  const currentAd = apps[currentAdIndex];

  const trackClick = () => {
    ReactGA.event({
      category: deviceOS,
      action: 'ad_click',
      label: currentAd.name
    });
  }

  return (
    <div className="banner-container">
      <div className="banner-content">
        {/* Logo Section - Constrained Size */}
        <div className="banner-logo">
          <img 
            src={currentAd.image} 
            alt={`${currentAd.name} logo`} 
            className="banner-logo-img"
          />
        </div>

        {/* Content Section */}
        <div className="banner-info">
          <div className="banner-text">
            <h3 className="banner-title">{currentAd.name}</h3>
            <p className="banner-description">{currentAd.description}</p>
          </div>
          
          <div className="banner-actions">
            <a 
              href={getAppStoreLink(currentAd, deviceOS)} 
              className="banner-download-btn"
              onClick={trackClick}
              target='_'
            >
              Download 
            </a>

            {/* Close Button */}
            {/* <button 
              onClick={() => setIsVisible(false)}
              className="banner-close-btn"
              aria-label="Close banner"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerAd;