export const getDeviceOS = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (/iphone|ipad|ipod/.test(userAgent)) {
        return 'ios';
    }

    if (/android/.test(userAgent)) {
        return 'android';
    }

    return 'unknown';
};